import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../../components/Layout';
import useWindowSize from '../../../helpers/useWindowSize';

const BlaSkjell = ({ data }) => {
  const [width, height] = useWindowSize();

  console.log(data);
  return (
    <Layout>
      <section className='bla-skjell'>
        <h1>blåskjell - smykkemerke</h1>
        <h2>
          <Link to='/work'>2018/design</Link>
        </h2>

        <Img
          fluid={data.image1.childImageSharp.fluid}
          alt='portrait'
          className='topimage'
        />

        <div className='banner'>
          <Img fluid={data.image2.childImageSharp.fluid} alt='two shells' />
          <Img
            fluid={data.image3.childImageSharp.fluid}
            alt='two larger shells'
          />
          <Img
            fluid={data.image4.childImageSharp.fluid}
            alt='two blue shells'
          />
          <Img
            fluid={data.image5.childImageSharp.fluid}
            alt='two green shells'
          />
        </div>

        <div className='mid-section'>
          <Img
            fluid={data.image6.childImageSharp.fluid}
            alt='person in water from above'
            className='leftimage'
          />
          <p className='maintext'>
            This is my own jewellery brand.
            <br />
            It was created to focus on an often overlooked gem in nature.
            <br />
            <br />I wanted to create something that highlighted the beauty in
            nature, but also created awareness around what climate change is
            doing to these treasures.
          </p>
          <p className='extratext'>
            The shells are handpicked by me and put together with freshwater
            pearls, crystals and other gems that enhance the shells natural
            beauty.
            <br />
            Every piece is one of a kind.
            <br />
            <br />
            Check out the instagram page{' '}
            <a href='https://www.instagram.com/bla_skjell/' target='_blank'>
              here
            </a>
          </p>

          <img
            src={require('../../../images/blåskjell/lotta2.jpg')}
            alt='bok'
            className='rightimage'
          />

          <Img
            fluid={data.image7.childImageSharp.fluid}
            alt='girl in chair in ocean'
            className='rightimage'
            style={{
              position: width > 1100 ? 'absolute' : 'relative',
            }}
          />
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query blaaimages {
    image1: file(relativePath: { eq: "blåskjell/lotta-3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "blåskjell/1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "blåskjell/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "blåskjell/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5: file(relativePath: { eq: "blåskjell/4.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image6: file(relativePath: { eq: "blåskjell/lotta1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image7: file(relativePath: { eq: "blåskjell/lotta2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default BlaSkjell;
